.body-img {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.top-title > h1 {
  color: rgb(255, 254, 254);
  text-align: center;
  font-size: 72px;
  font-family: basier_circlesemibold;
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 42%;
  letter-spacing: 1px;
  overflow: auto;
  resize: both;
}
.top-title > p {
  color: #fff;
  font-family: basier_circleregular;
  font-size: 32px;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 70%;
  letter-spacing: 1px;
}

.mid-title > h1 {
  color: rgb(255, 254, 254);
  text-align: left;
  font-size: 60px;
  font-family: basier_circlesemibold;
  position: absolute;
  z-index: 999;
  margin-top: 100px;
  left: 48%;
  right: 10%;
  letter-spacing: 2px;
}
.mid-title > p {
  color: rgb(255, 254, 254);
  text-align: left;
  font-size: 32px;
  font-family: basier_circleregular;
  position: absolute;
  z-index: 999;
  left: 48%;
  right: 10%;
  margin-top: 280px;
  letter-spacing: 2px;
}
.tre-sec {
  margin-top: -25px;
}
.l-sec {
  background-color: #e4f2d0;
  height: 300px;
}
.title-l > h1 {
  text-align: center;
  font-size: 40px;
  z-index: 999;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: 48px;
  font-family: basier_circlesemibold;
}
.title-l > p {
  text-align: center;
  font-size: 20px;
  z-index: 999;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: 120px;
  font-family: basier_circleregular;
}
.input-email {
  text-align: center;
  position: absolute;
  z-index: 999;
  margin-top: 200px;
  left: 0;
  right: 0;
}
input[type="email"] {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  height: 40px;
  width: 200px;
  font-size: 12px;
  padding-left: 12px;
  font-family: basier_circleregular;
}
.input-email button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
  height: 42px;
  background-color: #69eac0;
  width: 120px;
  font-size: 12px;
  font-family: basier_circleregular;
}

/* Div visible only for mobile diplay */
@media only screen and (min-width: 960px) {
  .mobi-div {
    display: none;
  }
}
/*Mobile screen*/
@media only screen and (max-width: 960px) {
  .body-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 100vh;
    display: flex;
  }
  .top-title > h1 {
    font-size: 34px;
    position: absolute;
    z-index: 999;
    font-family: basier_circlesemibold;
    text-align: center;
    letter-spacing: 2px;
    margin-top: -60px;
  }
  .top-title > p {
    font-size: 16px;
    margin-top: -140px;
    font-family: basier_circleregular;
    text-align: center;
    letter-spacing: 2px;
  }
  .mid-title > h1 {
    font-size: 34px;
    text-align: center;
    left: 60px;
    font-family: basier_circlesemibold;
    margin-top: 6px;
    letter-spacing: 2px;
    padding-right: 20px;
    display: none;
  }
  .mid-title > p {
    font-size: 16px;
    left: 70px;
    text-align: left;
    margin-top: 650px;
    font-family: basier_circleregular;
    letter-spacing: 2px;
    padding-right: 24px;
    color: white;
    font-weight: 900;
    display: none;
  }
  .l-sec {
    height: 250px;
  }

  .title-l > h1 {
    font-size: 28px;
    font-family: basier_circlesemibold;
    font-weight: bold;
  }
  .title-l > p {
    font-size: 16px;
    font-family: basier_circleregular;
    margin-top: 100px;
  }
  input[type="email"] {
    width: 140px;
    align-items: center;
    padding-right: -10px;
  }
  .input-email {
    margin-top: 170px;
    padding-right: 10px;
    height: 38px;
  }
  .input-email button {
    width: 110px;
    height: 40px;
  }

  .mobi-div {
    visibility: visible;
    height: 240px;
  }
  .mobi-div > h1 {
    text-align: center;
    font-family: basier_circlesemibold;
    font-size: 32px;
    margin-top: 20px;
  }
  .mobi-div > p {
    text-align: center;
    font-size: 16px;
    margin-top: 24px;
    font-family: basier_circleregular;
  }
  @media only screen and (max-width: 667px) {
    .body-img {
      height: 100vh;
    }
  }
  .top-title > p {
    margin-top: -120px;
  }
}
