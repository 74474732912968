.sec-footer {
  background-color: black;
  height: 120px;
}
.img-logo {
  margin-top: 42px;
  margin-left: 60px;
}

.copy-r > p {
  color: white;
  text-align: right;
  margin-right: 42px;
  margin-top: -30px;
  font-size: 14px;
  font-family: basier_circleregular;
}

.link {
  text-align: center;
  margin-top: -60px;
}

.link > a {
  color: white;
  font-family: basier_circleregular;
  font-size: 14px;
}
.apple-img {
  text-align: right;
  margin-right: 350px;
  margin-top: -72px;
}
.android-img {
  text-align: right;
  margin-right: 350px;
  margin-top: 5px;
}
.contact {
  font-size: 14px;
  font-family: basier_circleregular;
  color: white;
  margin-top: -70px;
  margin-left: 350px;
}
.contact > p > a {
  color: rgb(255 254 254);
}
/* Mobile screen */
@media screen and (max-width: 960px) {
  .sec-footer {
    height: 100px;
  }
  .img-logo {
    margin-top: 30px;
    margin-left: 20px;
    height: 24px;
  }
  .copy-r > p {
    margin-right: 24px;
    font-family: basier_circleregular;
    display: none;
  }
  .link {
    margin-top: 10px;
  }
  .link > a {
    font-size: 10px;
  }
  .apple-img {
    text-align: right;
    margin-right: 8px;
    margin-top: -72px;
  }
  .android-img {
    text-align: right;
    margin-right: 8px;
    margin-top: 5px;
  }
  .contact {
    text-align: left;
    margin-left: auto;
    font-size: 10px;
    padding-left: 8px;
  }
  .img-logo {
    display: none;
  }
}
