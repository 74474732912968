body {
  margin: 0;
  font-family: 'basier_circlebold', 'basier_circleregular','basier_circlesemibold'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'basier_circlebold';
  src: url('./Fonts/basiercircle-bold-webfont.eot');
  src: url('./Fonts/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiercircle-bold-webfont.woff2') format('woff2'),
       url('./Fonts/basiercircle-bold-webfont.woff') format('woff'),
       url('./Fonts/basiercircle-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'basier_circlebold_italic';
    src: url('./Fonts/basiercircle-bolditalic-webfont.eot');
    src: url('./Fonts/basiercircle-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Fonts/basiercircle-bolditalic-webfont.woff2') format('woff2'),
         url('./Fonts/basiercircle-bolditalic-webfont.woff') format('woff'),
         url('./Fonts/basiercircle-bolditalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
@font-face {
    font-family: 'basier_circlemedium';
    src: url('./Fonts/basiercircle-medium-webfont.eot');
    src: url('./Fonts/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Fonts/basiercircle-medium-webfont.woff2') format('woff2'),
         url('./Fonts/basiercircle-medium-webfont.woff') format('woff'),
         url('./Fonts/basiercircle-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
@font-face {
    font-family: 'basier_circlemedium_italic';
    src: url('./Fonts/basiercircle-mediumitalic-webfont.eot');
    src: url('./Fonts/basiercircle-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Fonts/basiercircle-mediumitalic-webfont.woff2') format('woff2'),
         url('./Fonts/basiercircle-mediumitalic-webfont.woff') format('woff'),
         url('./Fonts/basiercircle-mediumitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
 }
 @font-face {
  font-family: 'basier_circleregular';
  src: url('./Fonts/basiercircle-regular-webfont.eot');
  src: url('./Fonts/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiercircle-regular-webfont.woff2') format('woff2'),
       url('./Fonts/basiercircle-regular-webfont.woff') format('woff'),
       url('./Fonts/basiercircle-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
 }
 @font-face {
  font-family: 'basier_circlesemibold';
  src: url('./Fonts/basiercircle-semibold-webfont.eot');
  src: url('./Fonts/basiercircle-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiercircle-semibold-webfont.woff2') format('woff2'),
       url('./Fonts/basiercircle-semibold-webfont.woff') format('woff'),
       url('./Fonts/basiercircle-semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'basier_squarebold_italic';
  src: url('./Fonts/basiersquare-bolditalic-webfont.eot');
  src: url('./Fonts/basiersquare-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiersquare-bolditalic-webfont.woff2') format('woff2'),
       url('./Fonts/basiersquare-bolditalic-webfont.woff') format('woff'),
       url('./Fonts/basiersquare-bolditalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'basier_squaresemibold';
  src: url('./Fonts/basiersquare-semibold-webfont.eot');
  src: url('./Fonts/basiersquare-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiersquare-semibold-webfont.woff2') format('woff2'),
       url('./Fonts/basiersquare-semibold-webfont.woff') format('woff'),
       url('./Fonts/basiersquare-semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'basier_squarebold';
  src: url('./Fonts/basiersquare-bold-webfont.eot');
  src: url('./Fonts/basiersquare-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiersquare-bold-webfont.woff2') format('woff2'),
       url('./Fonts/basiersquare-bold-webfont.woff') format('woff'),
       url('./Fonts/basiersquare-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'basier_circlemedium_italic';
  src: url('./Fonts/basiercircle-mediumitalic-webfont.eot');
  src: url('./Fonts/basiercircle-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiercircle-mediumitalic-webfont.woff2') format('woff2'),
       url('./Fonts/basiercircle-mediumitalic-webfont.woff') format('woff'),
       url('./Fonts/basiercircle-mediumitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'basier_circlemedium';
  src: url('./Fonts/basiercircle-medium-webfont.eot');
  src: url('./Fonts/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('./Fonts/basiercircle-medium-webfont.woff2') format('woff2'),
       url('./Fonts/basiercircle-medium-webfont.woff') format('woff'),
       url('./Fonts/basiercircle-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}