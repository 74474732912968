.header {
align-items: center;
text-align: center;
width: auto;
top: auto;
height: 170px;
margin-top: -40px;
}
.hader-logo {
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
}
/* Mobiel screen */
@media screen  and (max-width: 960px){
    .header {
        margin-top: -48px;
        height: 143px;
    }
}