.card-title, h5 {
    text-align: left;
    padding-top: 10px;
    font-family: 'basier_circlesemibold';
    font-size: 16px;
   
}

p {
    text-align: justify;
    font-family: 'basier_circleregular';
}

.p-1, .p-2 {
    margin: 2px;
}
.p-3 {
    margin: -8px;
}

.first-1, .first-2, .first-3 {
    padding-left: 20px;
} 